<template>
  <div class="container">
    <el-tabs v-if="dataList.length" v-model="activeName" class="tabs">
      <el-tab-pane v-for="(formData, i) in dataList" :key="i" :label="formData.title + i" :name="'' + i">
        <LossTem :formData="formData" :pdfDownload="false" />
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import LossTem from '@/components/Template/loss.vue'
export default {
  components: { LossTem },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    dictId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      // formData: {}
      dataList: [],
      activeName: ''
    }
  },
  computed: {
    declareTotal() {
      return (formData) => {
        if (!formData.decorationLossObj) return
        const value = formData.decorationLossObj.reduce((prev, curr) => {
          return prev + curr.burningArea * curr.buildingValue
        }, 0)
        return value.toFixed(2)
      }
    },
    practicalTotal() {
      return (formData) => {
        if (!formData.decorationLossObj) return
        const value = formData.decorationLossObj.reduce((prev, curr) => {
          return prev + parseFloat(this.calcValue(curr))
        }, 0)
        return value.toFixed(2)
      }
    },
    deviceDeclareTotal() {
      return (formData) => {
        if (!formData.deviceLossObj) return
        const value = formData.deviceLossObj.reduce((prev, curr) => {
          return prev + curr.count * curr.buyingValue
        }, 0)
        return value.toFixed(2)
      }
    },
    devicePracticalTotal() {
      return (formData) => {
        if (!formData.deviceLossObj) return
        const value = formData.deviceLossObj.reduce((prev, curr) => {
          return prev + parseFloat(this.calcDeviceValue(curr))
        }, 0)
        return value.toFixed(2)
      }
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val.length) {
          this.dataList = val
          this.activeName = '0'
        }
      }
    }
  },
  methods: {
    calcValue(item) {
      let value = item.burningArea1 * item.rebuildValue * (1 - item.buildedYear / item.rebuildYear)
      if (item.userable) {
        value = value * item.burningRate / 100
      }
      return value.toFixed(2)
    },
    calcDeviceValue(item) {
      const rate = parseFloat((1 - 1 / item.reusedYear * item.usedValue).toFixed(2))
      let value = item.unitCount * item.unitPrice * rate
      if (item.userable) {
        value = value * item.burningRate / 100
      }
      return value.toFixed(2)
    }
  }
}
</script>

<style></style>
