<template>
    <div id="view_warper" ref="pdfDom" :class="{ pdf_download: pdfDownload }" class="b-l view_warper">
        <h1 class="t-a-c">火灾直接财产损失申报统计表</h1>
        <table border="1" cellspacing="0" cellpadding="0" class="table_content">
            <tr>
                <th colspan="10">以下由受损单位（个人）填写</th>
                <th colspan="10">以下由消防机构填写</th>
            </tr>
            <tr>
                <th :rowspan="maxDecorationRowspan" class="vertical">建构筑物及装修</th>
                <th style="height: 63px" class="vertical">序号</th>
                <th colspan="2">建筑结构及装修名称</th>
                <th colspan="2">烧损面积（m2）</th>
                <th colspan="2">建造时价格（元）</th>
                <th colspan="2">已使用时间（年）</th>
                <th colspan="2">折旧年限（年）</th>
                <th colspan="2">烧损率（%）</th>
                <th colspan="2">烧损面积（m2）</th>
                <th colspan="2">重置价值或修复费（元）</th>
                <th colspan="2">统计损失（元）</th>
            </tr>
            <tr v-for="(item, index) in formData.lossDecorationList" :key="index + 'a'">
                <td style="height: 26px">{{ index + 1 }}</td>
                <td colspan="2">{{ item.name }}</td>
                <td colspan="2">{{ item.burningArea.toFixed(2) }}</td>
                <td colspan="2">{{ item.buildingValue.toFixed(2) }}</td>
                <td colspan="2">{{ item.buildedYear && item.buildedYear.toFixed(2) }}</td>
                <td colspan="2">{{ item.rebuildYear && item.rebuildYear.toFixed(2) }}</td>
                <td colspan="2">{{ item.burningRate && item.burningRate.toFixed(2) }}</td>
                <td colspan="2">{{ item.burningArea1 && item.burningArea1.toFixed(2) }}</td>
                <td colspan="2">{{ item.rebuildValue && item.rebuildValue.toFixed(2) }}</td>
                <td colspan="2">{{ calcValue(item) }}</td>
                <!-- {{ item.rebuildValue }} -->
            </tr>

            <template v-if="formData.lossDecorationList && formData.lossDecorationList.length < 8">
                <tr v-for="index in 8 - formData.lossDecorationList.length" :key="index + 'b'">
                    <td style="height: 26px">{{ index + formData.lossDecorationList.length }}</td>
                    <td v-for="(value, key) in 8" :key="key + 'b'" colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </tr>
            </template>
            <tr>
                <td style="height: 31px" colspan="9">
                    <div class="total-wrapper">
                        <span>申报损失小计</span>
                        <span>{{ declareTotal || "&nbsp;" }}元</span>
                    </div>
                </td>
                <td colspan="10">
                    <div class="total-wrapper">
                        <span>统计损失小计</span>
                        <span>{{ practicalTotal || "&nbsp;" }}元</span>
                    </div>
                </td>
            </tr>
            <tr>
                <th :rowspan="maxDeviceRowspan" class="vertical">设备及其他财产</th>
                <th rowspan="2" style="height: 63px" class="vertical">序号</th>
                <th rowspan="2" colspan="2">名称</th>
                <th rowspan="2" colspan="2">数量</th>
                <th rowspan="2" colspan="2">购进时单价（元）</th>
                <th rowspan="2" colspan="2">已使用时间（年）</th>
                <th rowspan="2" colspan="2">折旧年限（年）</th>
                <th rowspan="2" colspan="2">烧损率（%）</th>
                <th colspan="4" style="height: 23px;">重置价值</th>
                <th rowspan="2" colspan="2">统计损失（元）</th>
            </tr>
            <tr>
                <th colspan="2" style="height: 40px">单价</th>
                <th colspan="2">数量</th>
            </tr>

            <tr v-for="(item, index) in formData.lossDeviceList" :key="index + 'c'">
                <td style="height: 28px">{{ index + 1 }}</td>
                <td colspan="2">{{ item.name }}</td>
                <td colspan="2">{{ item.count }}</td>
                <td colspan="2">{{ item.buyingValue && item.buyingValue.toFixed(2) }}</td>
                <td colspan="2">{{ item.usedValue && item.usedValue.toFixed(2) }}</td>
                <td colspan="2">{{ item.reusedYear && item.reusedYear.toFixed(2) }}</td>
                <td colspan="2">{{ item.burningRate && item.burningRate.toFixed(2) }}</td>
                <td colspan="2">{{ item.unitPrice && item.unitPrice.toFixed(2) }}</td>
                <td colspan="2">{{ item.unitCount }}</td>
                <td colspan="2">{{ calcDeviceValue(item) || "&nbsp;" }}</td>
            </tr>

            <template v-if="formData.lossDeviceList && formData.lossDeviceList.length < 7">
                <tr v-for="index in 7 - formData.lossDeviceList.length" :key="index + 'd'">
                    <td style="height: 28px">{{ index + formData.lossDeviceList.length }}</td>
                    <td v-for="(value, key) in 8" :key="key + 'c'" colspan="2">
                        <span>&nbsp;</span>
                    </td>
                    <td colspan="2">&nbsp;</td>
                </tr>
            </template>

            <tr>
                <td colspan="9" style="height: 33px;">
                    <div class="total-wrapper">
                        <span>申报损失小计</span>
                        <span>{{ deviceDeclareTotal }}元</span>
                    </div>
                </td>
                <td colspan="10">
                    <div class="total-wrapper">
                        <span>统计损失小计</span>
                        <span>{{ devicePracticalTotal }}元</span>
                    </div>
                </td>
            </tr>
            <tr>
                <th colspan="10" style="height: 43px;">
                    <div class="total-wrapper">
                        <span>申报损失总计</span>
                        <span>{{ parseFloat(declareTotal) + parseFloat(deviceDeclareTotal) }}元</span>
                    </div>
                </th>
                <th colspan="10">
                    <div class="total-wrapper">
                        <span>统计损失总计</span>
                        <span>{{ parseFloat(practicalTotal) + parseFloat(devicePracticalTotal) }}元</span>
                    </div>
                </th>
            </tr>
            <tr>
                <th colspan="10">
                    <div style="min-height: 120px; padding: 0 10px; line-height: 32px;font-size: 13px"
                        class="text_align_left">
                        <div class="text_align_left" flex="cross:center">
                            <span>受损单位填表人（签名）：</span>
                            <!-- <span>{{ formData.preparer }}</span> -->
                            <img :src="formData.preparer" alt="" srcset="" height="32" />
                        </div>
                        <div class="text_align_left" flex="cross:center">
                            <div>
                                申报日期：
                                <span>{{ parseTime(formData.preparerTime, "{y}年{m}月{d}日") }}</span>
                            </div>
                        </div>
                        <div class="text_align_left">
                            受损单位（个人）联系人：
                            <span>{{ formData.contactor }}</span>
                        </div>
                        <div class="text_align_left" flex="cross:center">
                            <div> 联系电话：
                                <span>{{ formData.contactorPhone }}</span>
                            </div>
                        </div>
                    </div>
                </th>
                <th colspan="10" style="line-height: 36px;text-align: left;padding: 10px;font-size: 13px;">
                    <div flex>
                        <span style="display:inline-block;width: 80px;">统计单位：</span>
                        <span>{{ formData.statisticsOrg }}</span>
                    </div>
                    <div flex="main:justify">
                        <span style="display:inline-block;width: 100px;"> 统计人（签名）:</span>
                        <span>{{ formData.statisticstor }}</span>
                        <div>
                            <span style="margin-left: 10px">{{ parseTime(formData.statisticsTime, "{y}年{m}月{d}日") }}</span>
                        </div>
                    </div>
                    <div flex="main:justify">
                        <span style="display:inline-block;width: 100px;">审批人（签名）:</span>
                        <span>{{ formData.approvaltor }}</span>
                        <div>
                            <span style="margin-left: 10px">{{ parseTime(formData.approvalTime, "{y}年{m}月{d}日") }}</span>
                        </div>
                    </div>
                </th>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        formData: {
            type: Object,
            default: () => ({ lossDecorationList: [], lossDeviceList: [] })
        },
        pdfDownload: {
            type: Boolean,
        }
    },
    computed: {
        declareTotal() {
            if (!this.formData.lossDecorationList) return;
            const value = this.formData.lossDecorationList.reduce((prev, curr) => {
                return prev + curr.burningArea * curr.buildingValue;
            }, 0);
            return value.toFixed(2);
        },
        practicalTotal() {
            if (!this.formData.lossDecorationList) return;
            const value = this.formData.lossDecorationList.reduce((prev, curr) => {
                return prev + parseFloat(this.calcValue(curr));
            }, 0);
            return value.toFixed(2);
        },
        deviceDeclareTotal() {
            if (!this.formData.lossDeviceList) return;
            const value = this.formData.lossDeviceList.reduce((prev, curr) => {
                return prev + curr.count * curr.buyingValue;
            }, 0);
            return value.toFixed(2);
        },
        devicePracticalTotal() {
            if (!this.formData.lossDeviceList) return;
            const value = this.formData.lossDeviceList.reduce((prev, curr) => {
                return prev + parseFloat(this.calcDeviceValue(curr));
            }, 0);
            return value.toFixed(2);
        },
        maxDecorationRowspan() {
            const { lossDecorationList } = this.formData;
            return this.getRowspan(lossDecorationList, 2);
        },
        maxDeviceRowspan() {
            const { lossDeviceList } = this.formData;
            return this.getRowspan(lossDeviceList, 3);
        },
        getRowspan() {
            return (list, additionalRows) => {
                const defaultRowspan = 10;
                const actualRowspan = list ? list.length + additionalRows : 0;
                return Math.max(defaultRowspan, actualRowspan);
            };
        },
    },
    methods: {
        calcValue(item) {
            let value = item.burningArea1 * item.rebuildValue * (1 - item.buildedYear / item.rebuildYear);
            if (item.userable) {
                value = (value * item.burningRate) / 100;
            }
            return value.toFixed(2);
        },
        calcDeviceValue(item) {
            const rate = parseFloat((1 - (1 / item.reusedYear) * item.usedValue));
            let value = item.unitCount * item.unitPrice * rate;
            if (item.userable) {
                value = (value * item.burningRate) / 100;
            }
            return value.toFixed(2);
        },
    }
}
</script>
<style scoped lang="scss">
.view_warper {
    font-size: 17px;
    color: #000;
    padding: 15px 30px;
    overflow-y: auto;

    &.pdf_download {
        padding: 63.5px 79.25px;
        font-size: 22px;
    }

    p,
    pre {
        margin: 0;
        line-height: 40px;

        span {
            text-decoration: underline;
            display: inline-block;
        }
    }

    pre {
        text-decoration: underline;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .table_content {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        vertical-align: center;
        box-sizing: border-box;
        color: #606266;
        font-size: 13px;
        table-layout: fixed;
        height: 758px;
        font-size: 16px;

        .vertical {
            width: 36px;
            writing-mode: vertical-rl;
        }

        .total-wrapper {
            padding: 0 30px 0 10px;
            display: flex;
            justify-content: space-between;
        }

        td {
            position: relative;
            word-wrap: break-word;
            word-break: break-all;

            &>input,
            &>textarea,
            &>.box {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                text-align: left;
                padding-left: 10px;
                box-sizing: border-box;
                border: none;
                resize: none;
                font-family: inherit;
                background-color: #ebf0ff;
            }

            .el-input__inner {
                border: none;
            }

            input[type="date"],
            input[type="time"] {
                background-color: #ebf0ff;
            }
        }
    }
}
</style>